import EventBus from '@/util/EventBus.js'
import { FontTypes } from '../../../../enums/FontTypes.js'
import MarginProperty from '../../common/margin-property/MarginProperty.vue'
import ColorPicker from '@/components/ColorPicker/ColorPicker.vue'
import ButtonMultimedia from '@/components/MultimediaManager/ButtonMultimedia/ButtonMultimedia.vue'
import Editor from '@tinymce/tinymce-vue'
import getEnv from '@/util/env'
import FormsService from '@/services/forms.service.js'
import moment from 'moment'
import { isObjEmpty } from '@/util/ValidateObject.js'
import OverlayManager from '@/components/OverlayManager/OverlayManager.vue'
import FormsEditor from '@/components/FormsEditor/FormsEditor.vue'
import TextEditor from '@/components/TextEditor/TextEditor.vue'

export default {
  name: 'ButtonProperties',
  components: {
    MarginProperty,
    ButtonMultimedia,
    Editor,
    ColorPicker,
    OverlayManager,
    FormsEditor,
    TextEditor,
  },
  props: {
    landing: { type: Object },
    containerIndex: { type: Number },
    slotIndex: { type: Number },
    inComponent: { type: Boolean, default: false },
  },
  data: function () {
    return {
      FontTypes: FontTypes,
      component: { type: Object },
      typeActionSelected: {
        link: 'link',
        download: 'download',
        form: 'form',
        custom: 'custom',
      },
      forms: null,
      fields: {},
      showFormEditor: false,
      editorApiKey: getEnv('VUE_APP_TINY_API_KEY'),
      init: {
        selector: 'textarea#full-featured-non-premium',
        plugins: 'fullscreen hr advlist lists wordcount quickbars emoticons code',
        toolbar: ['undo redo forecolor backcolor numlist bullist indentlist alignment | hr emoticons code', 'custonButton'],
        toolbar_sticky: true,
        branding: false,
        image_advtab: false,
        importcss_append: true,
        height: 300,
        contextmenu: false,
        lineheight_formats: '1 1.2 1.5 2 2.3 2.5 3',
        quickbars_insert_toolbar: 'alignleft aligncenter alignright alignfull | outdent indent',
        quickbars_selection_toolbar: 'bold italic underline lineheight | fontselect fontsizeselect',
        toolbar_mode: 'floating',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; color: #3F4254; }',
        menubar: 'custom',
        setup: function (editor) {
          editor.ui.registry.addGroupToolbarButton('alignment', {
            icon: 'align-left',
            tooltip: 'Alignment',
            items: 'alignleft aligncenter alignright alignjustify',
          })
          editor.ui.registry.addGroupToolbarButton('indentlist', {
            icon: 'indent',
            tooltip: 'IndentList',
            items: 'outdent indent',
          })
          editor.ui.registry.addGroupToolbarButton('fontDecorations', {
            icon: 'bold',
            tooltip: 'FontDecorations',
            items: 'bold italic underline strikethrough | removeformat',
          })
        },
      },
      renderEditor: false,
    }
  },
  created: function () {
    if (this.inComponent) {
      this.component = this.landing.structure.containers[this.containerIndex].slots[this.slotIndex][0].buttonComponent
    } else {
      this.component = this.landing.structure.containers[this.containerIndex].slots[this.slotIndex][0]
    }

    this.initEditor()
    if (this.component.action === this.typeActionSelected.form) {
      this.getForms()
    }
  },
  mounted: function () {
    const that = this
    const elements = document.querySelectorAll('.tox .tox-edit-area__iframe')
    elements.forEach(function (elemen) {
      elemen.setAttribute('style', 'background-color: ' + that.landing.structure.containers[that.containerIndex].backgroundColor + ' !important;')
    })

    EventBus.$on('multimediaSelected', this.setComponentData)
    EventBus.$on('hook:beforeDestroy', () => {
      EventBus.$off('multimediaSelected', this.setComponentData)
    })

    EventBus.$on('updateConctactFields', (fields) => {
      this.fields = fields
    })
    EventBus.$emit('getUpdatedConctactFields')
  },
  computed: {
    customVars: function () {
      if (!isObjEmpty(this.landing.customVars)) {
        // console.log('landing.customVars: ', this.landing.customVars)
          return true
      } else {
        return false
      }
    },
  },
  methods: {

    async initEditor () {
      if (this.component.tinymce === '') {
        this.component.tinymce = `<p style="
          text-align: ${this.component.alignment};
          font-size: ${this.component.fontSize}px;
          font-familiy: ${this.component.font};
          color: ${this.component.color};
          margin: 0;
        ">${this.component.text}</p>`
      }
      this.renderEditor = true
    },

    showOverlayFormEditor () {
      EventBus.$emit('showOverlayFormEditorFromLandingEditor')
    },
    closeFormsEditor () {
      EventBus.$emit('closeOverlayFormEditorFromLandingEditor')
    },
    setComponentData (url, type, fileId, fileName) {
      this.component.fileName = fileName
      if (type === 'attachment') {
        this.component.fileUrl = getEnv('backend_assets_url') + url
      }

      if (type === 'form') {
        const user = JSON.parse(localStorage.getItem('user'))
        this.component.fileId = fileId
        this.component.previewUrl = getEnv('backend_endpoint') + 'api/forms/preview/' + fileId + '?token=' + user.token
        this.component.url = '[FORM_' + fileId + ']'
      }
    },
    typeUrl () {
      this.component.url = this.component.url.replace('mailto:', '')
      this.component.url = this.component.url.replace('tel:', '')
      if (this.component.url.match(/^([a-z0-9_.-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/)) {
        this.component.url = 'mailto:' + this.component.url
      } else if (this.component.url.match(/^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[-. \\/]?)?((?:\(?\d{3,}\)?[-. \\/]?){3,})(?:[-. \\/]?(?:#|ext\.?|extension|x)[-. \\/]?(\d+))?$/i)) {
        this.component.url = 'tel:' + this.component.url
      } else if (this.component.url.match(/[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/)) {
        this.component.url = this.component.url.replace('mailto:', '')
        this.component.url = this.component.url.replace('tel:', '')
      }
    },
    filterCustomVar (elementCustomVar) {
      // COL1 - smas@nrs-group.com (name)
      // console.log('elementCustomVar:', elementCustomVar.replace(/COL[0-9]+\s[-]/i, ''))
      let val = elementCustomVar.replace(/COL[0-9]+\s[-]/i, '')
      // Remove last chars
      val = val.replace(/\s\([^)]+\)$/i, '')
      return val
    },
    getForms () {
      const params = {
        page: 1,
        perPage: 50,
        startDate: moment('2016-01-01').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      }
      FormsService.getForms(params).then(
        (response) => {
          this.forms = response.data
        },
        (error) => {
          FormsService.errorResponse(
            error.response.status,
            this.$t('Error al intentar cargar los formularios'),
            this.$t(error.response.data.message),
          )
        },
      )
      .finally(() => {
        this.loading = false
      })
    },
    loadActionUrl () {
      this.component.fileName = ''
      if (this.component.action === this.typeActionSelected.form) {
        this.getForms()
      }
    },
    showMultimedia (currentRootDirectory) {
      EventBus.$emit('showMultimedia', currentRootDirectory, false)
    },
  },
}
