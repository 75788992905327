import { ContainerDistributionColumns } from '../../../../enums/ContainerDistributionColumns.js'
import ColorPicker from '@/components/ColorPicker/ColorPicker.vue'
import { BackgroundType } from '@/components/Template/LandingEditor/classes/BackgroundType.js'
import ButtonMultimedia from '@/components/MultimediaManager/ButtonMultimedia/ButtonMultimedia.vue'
import getEnv from '@/util/env'

export default {
  name: 'ContainerProperties',
  components: { ColorPicker, ButtonMultimedia },
  props: {
    landing: { type: Object },
    containerIndex: Number,
  },
  data: function () {
    return {
      backgroundType: new BackgroundType(),
      component: { type: Object },
      containerOptions: [
        { slots: 1, distribution: ContainerDistributionColumns.Equal, text: '1', icon: 'container-type-1' },
        { slots: 2, distribution: ContainerDistributionColumns.Equal, text: '2', icon: 'container-type-2' },
        { slots: 3, distribution: ContainerDistributionColumns.Equal, text: '3', icon: 'container-type-3' },
        { slots: 2, distribution: ContainerDistributionColumns.OneAndTwo, text: '1/3 : 2/3', icon: 'container-type-1-2' },
        { slots: 2, distribution: ContainerDistributionColumns.TwoAndOne, text: '2/3 : 1/3', icon: 'container-type-2-1' },
        { slots: 4, distribution: ContainerDistributionColumns.Equal, text: '4', icon: 'container-type-4' },
      ],
      loadingPreview: false,
      imageLoadError: true,
    }
  },
  created: function () {
    this.component = this.landing.structure.containers[this.containerIndex]
    if (this.component.backgroundColor) {
      let hexColor = this.component.backgroundColor
      if (this.component.backgroundColor.length === 7) {
        hexColor = hexColor + 'FF'
      }
      this.component.backgroundColor = hexColor
    }
  },
  methods: {
    selectColumnByValue (value) {
      const option = this.containerOptions[value]
      this.selectColumnByOption(option)
    },
    selectColumnType (event) {
      const option = this.containerOptions[event.target.value]
      this.selectColumnByOption(option)
    },
    selectColumnByOption (option) {
      const actualSlots = this.component.slots.length

      if (option.slots > actualSlots) {
        const slotsToAdd = option.slots - actualSlots
        for (let i = 0; i < slotsToAdd; i++) {
          this.component.slots.push([])
        }
      } else if (option.slots < actualSlots) {
        const slotsToRemove = actualSlots - option.slots
        for (let i = 0; i < slotsToRemove; i++) {
          this.component.slots.pop()
        }
      }
      if (option.distribution !== this.component.distribution) {
        this.component.distribution = option.distribution
      }
    },

    setThumbnail (url) {
      this.component.backgroundPath = getEnv('backend_assets_url') + url
      this.$forceUpdate()
    },
    showLoading () {
      this.loadingPreview = true
      this.imageLoadError = false
    },
    hideLoading () {
      this.loadingPreview = false
      this.imageLoadError = false
    },
    showError () {
      this.loadingPreview = false
      this.imageLoadError = true
    },
    removeImageUrl () {
      this.component.backgroundPath = ''
      this.$forceUpdate()
    },

  },
}
