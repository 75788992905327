import ButtonMultimedia from '@/components/MultimediaManager/ButtonMultimedia/ButtonMultimedia.vue'
import MarginProperty from '../../common/margin-property/MarginProperty.vue'
import SizeProperty from '../../common/size-property/SizeProperty.vue'
import getEnv from '@/util/env'

export default {
  name: 'ImageProperties',
  components: { ButtonMultimedia, MarginProperty, SizeProperty },
  props: {
    landing: { type: Object },
    containerIndex: Number,
    slotIndex: Number,
    inComponent: Boolean,
  },
  data: function () {
    return {
      loadingPreview: true,
      imageLoadError: false,
      component: { type: Object },
    }
  },
  created: function () {
    if (this.inComponent) {
      this.component = this.landing.structure.containers[this.containerIndex].slots[this.slotIndex][0].imageComponent
    } else {
      this.component = this.landing.structure.containers[this.containerIndex].slots[this.slotIndex][0]
    }
  },
  methods: {
    setThumbnail (url) {
      this.component.path = getEnv('backend_assets_url') + url
    },

    showLoading () {
      this.loadingPreview = true
      this.imageLoadError = false
    },

    hideLoading () {
      this.loadingPreview = false
      this.imageLoadError = false
    },

    showError () {
      this.loadingPreview = false
      this.imageLoadError = true
    },

    removeImageUrl () {
      this.component.path = ''
    },
  },
}
